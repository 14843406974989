import React, { useState, useEffect } from "react";

import withRouter from "../global/withRouter";
import { Routes, Route } from "react-router-dom";

import Home from "../home/Home";
import Create from "../create/Create";

const RoutesRoot = (props) => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/create" element={<Create />} />
      </Routes>
    </>
  );
};

export default withRouter(RoutesRoot);
