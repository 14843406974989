import "@lottiefiles/lottie-player";

const anim = require("../../assets/loader.json");
const Loader = (props) => {
  const { size = 60 } = props;
  return (
    <div className="loader">
      <lottie-player
        autoplay
        loop
        src={JSON.stringify(anim)}
        style={{ width: size }}
      ></lottie-player>
    </div>
  );
};
export default Loader;
