import { useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import { withCookies } from "react-cookie";
import CookieConcent from "./components/global/CookieConcent";

import RoutesRoot from "./components/global/Root";
const App = () => {
  return (
    <div className={isMobile ? "App Mobile" : "App Desk"}>
      <RoutesRoot />
      <CookieConcent />
    </div>
  );
};

export default withCookies(App);
