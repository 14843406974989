import { useState, useRef, useEffect } from "react";
import axios from "axios";
import Vimeo from "@u-wave/react-vimeo";
import gsap from "gsap";
import { orderBy, filter, includes } from "lodash";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { trackAction } from "../../data/Tracker";
import copy from "copy-to-clipboard";
import clogo from "../../assets/create.svg";
import flogo from "../../assets/final.svg";
import mlogo from "../../assets/select_music.svg";
import arrow from "../../assets/arrow.svg";
import close from "../../assets/close.svg";
import share from "../../assets/share.svg";

import facebook from "../../assets/f.svg";
import instagram from "../../assets/i.svg";
import snapchat from "../../assets/s.svg";
import whatsapp from "../../assets/w.svg";
import Slider from "../global/Slider";
import Loader from "../global/Loader";

const Create = () => {
  const activeC = "#FD9C0C";
  const [step, setStep] = useState(1);
  const [name, setName] = useState(false);
  const [filtered, setFiltered] = useState("");
  const [open, setOpen] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [names, setNames] = useState([]);
  const [vidHeight, setVidHeight] = useState(false);
  const [filtNames, setFiltNames] = useState([]);
  const navigate = useNavigate();
  const [playingTune, setPlayingTune] = useState(false);
  const [currentTune, setCurrentTune] = useState(false);
  const [selectedTune, setSelectedTune] = useState(false);
  const [showNickNames, setShowNickNames] = useState(false);
  const [miniLoader, showMiniLoader] = useState(false);
  const [bigLoader, showBigLoader] = useState(false);
  const [genreList, setGenreList] = useState([]);

  const Vid = useRef(null);
  const videoHolder = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const Tune = useRef(null);
  const webShareSupported = "canShare" in navigator;

  

  let w = isMobile ? window.innerWidth * 0.85 : 400;
  if(w > 500){
    w = 500
  }
  let h = isMobile ? (w * 16) / 9 : (w * 16) / 9;
  const shareOrDownload = async (blob, fileName, title, text) => {
    if (webShareSupported) {
      const data = {
        files: [
          new File([blob], fileName, {
            type: "video/mp4",
          }),
        ],
        title,
        text,
      };
      if (navigator.canShare) {
        try {
          await navigator.share(data);
        } catch (err) {
          showBigLoader(false);
          if (err.name !== "AbortError") {
            console.error(err.name, err.message);
          }
        } finally {
          showBigLoader(false);
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (
      Vid.current &&
      !isPlaying &&
      selectedTune?.genre.toLowerCase() === "hiphop"
    ) {
      setTimeout(() => {
        Vid.current.seekTo(1, "seconds");
      }, 100);
    }
  }, [Vid.current]);

  useEffect(() => {
    if (videoHolder.current) {
      setVidHeight(videoHolder.current.clientHeight * 0.8);
    }
  });

  useEffect(() => {
    if (filtered.length === 0 || filtered === " " || filtered === "") {
      setFiltNames([]);
    } else {
      let filt = filter(names, (val) => {
        return val.name.toLowerCase().indexOf(filtered.toLowerCase()) > -1;
      });

      setFiltNames(filt);
    }
  }, [filtered]);

  const getList = (nicknames = false) => {
    let list = filtNames.length <= 0 && filtered === "" ? names : filtNames;

    let a = [];
    list.map((item, index) => {
      if (!nicknames && item.type === 1) return;
      if (nicknames && item.type === 0) return;

      a.push(
        <div
          key={`namn_n${index}`}
          className={
            name?.name === item.name ? "listName selected" : "listName"
          }
          onClick={() => {
            setName(item);
            let g = item?.genre;
            g = orderBy(g, "genre");
            setGenreList(g);
            setFiltered("");
            setFiltNames([]);
            setOpen(false);
            setShowNickNames(false);
          }}
        >
          <p>
            {item.name}
            {name?.name === item.name && <span className="check"></span>}
          </p>
          <span className="line"></span>
        </div>,
      );
    });
    return a;
  };
  useEffect(() => {
    if (
      playingTune === false &&
      currentTune !== false &&
      playingTune.genre !== currentTune?.genre
    ) {
      if (Tune.current) {
        Tune.current.pause();
      }
    } else if (playingTune !== false) {
      setCurrentTune(playingTune);
      if (Tune.current) {
        Tune.current.pause();
      }

      setTimeout(() => {
        Tune.current = new Audio(playingTune.mp3);
        Tune.current.play();
      }, 150);
    }
  }, [playingTune]);

  useEffect(() => {
    if (names.length <= 0) {
      showMiniLoader(true);
      axios
        .get(
          "https://yw7u0v13h6.execute-api.eu-central-1.amazonaws.com/getNames",
        )
        .then((data) => {
          if (data?.data) {
            let ns = [];
            Object.keys(data.data).forEach((item) => {
              let g = [];
              Object.keys(data.data[item]).forEach((genre) => {
                if (genre !== "type" && genre !== "share_name") {
                  let o = {
                    genre,
                    mp3: data.data[item][genre][0].mp3,
                    mp4: data.data[item][genre][0].mp4,
                  };
                  g.push(o);
                }
              });

              ns.push({
                name: item,
                type: data.data[item]["type"],
                share_name: data.data[item]["share_name"],
                genre: [...g],
              });
            });
            showMiniLoader(false);
            setNames(ns);
          }
        })
        .catch((e) => {
          showMiniLoader(false);
        });
    }
  }, []);
  return (
    <>
      <div className="create">
        <div className="controls">
          <div
            className="close"
            onClick={() => {
              setFiltered("");
              setFiltNames([]);
              setGenreList([]);
              setName(false);
              setShowNickNames(false);
              setOpen(false);
              setSelectedTune(false);
              setStep(1);
              if (Tune.current) {
                Tune.current.pause();
              }
              navigate("/");
            }}
          >
            Avbryt
          </div>
        </div>
        {step !== 3 && (
          <div className="steps">
            <svg
              x="0px"
              y="0px"
              viewBox="0 0 224.4 25.5"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                stroke={step === 3 ? activeC : "#002D57"}
                strokeWidth="4.4"
                d="M117.7,11.5c31.7,0.6,64.7,0.6,96.3,2.6"
              />
              <path
                stroke={step >= 2 ? activeC : "#002D57"}
                strokeWidth="4.4"
                d="M10.9,14c31.5-1.9,64.5-1.9,96.3-2.5"
              />
              <circle
                fill={step === 3 ? activeC : "#002D57"}
                cx="214"
                cy="14.1"
                r="9.4"
              />
              <circle
                fill={step >= 1 ? activeC : "#002D57"}
                cx="10.9"
                cy="14.1"
                r="9.4"
              />
              <circle
                fill={step >= 2 ? activeC : "#002D57"}
                cx="112.5"
                cy="11.5"
                r="9.4"
              />
            </svg>
            <div className="labels">
              <span>
                <p>Steg 1:</p>Välj namn
              </span>
              <span>
                <p>Steg 2:</p>Välj musik
              </span>
              <span>
                <p>Steg 3:</p>Dela videon
              </span>
            </div>
          </div>
        )}
        <div className={"stepHolder _" + step}>
          {step === 1 && (
            <>
              <div className="step _1">
                <div className="ccontent">
                  <img src={clogo} />
                  <p>
                    Fyll i namnet på personen du vill bjuda in och välj
                    musikgenre. En personlig låt och video genereras som du kan
                    dela direkt eller ladda ner.
                  </p>
                  <div
                    className="blankBtn pickname"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    {name === false ? "Välj namn" : name.name}
                  </div>
                </div>
                <div className="foot">
                  <div
                    className={name ? "next" : "next disabled"}
                    onClick={() => {
                      if (name !== false) {
                        trackAction("click", { name: name.name });
                        setStep(2);
                      }
                    }}
                  >
                    <img src={arrow} />
                  </div>
                </div>
              </div>
              {!open && (
                <div
                  className="bottVideoHolder"
                  style={
                    !isMobile
                      ? {
                          marginTop: 50,
                          height: h,
                          justifyContent: "center",
                        }
                      : { marginTop: 50, height: h, justifyContent: "center" }
                  }
                >
                  <Slider
                    width={isMobile ? window.innerWidth : w}
                    custW={w}
                    height={h}
                  />
                </div>
              )}
            </>
          )}
          {step === 2 && (
            <div className="step _2">
              <div className="ccontent">
                <img src={mlogo} />
                <p>
                  Välj vad du gillar bäst och en personlig låt och video
                  genereras.
                </p>
              </div>
              <div className="s2content">
                <div className="listMusic">
                  {genreList.length > 0 &&
                    genreList.map((item, index) => {
                      return (
                        <div
                          className={"genre"}
                          key={`musik_${index}`}
                          onClick={() => {
                            if (selectedTune?.genre === item.genre) {
                              setSelectedTune(false);
                            } else {
                              setSelectedTune(item);
                            }
                          }}
                        >
                          <div className="g_info">
                            <p>
                              <span
                                className={
                                  selectedTune?.genre !== item.genre
                                    ? "selectBox"
                                    : "selectBox selected"
                                }
                              ></span>
                              {item.genre}
                            </p>
                            <div
                              className={
                                playingTune?.genre === item.genre
                                  ? "btnMusic playing"
                                  : "btnMusic"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (playingTune?.genre === item.genre) {
                                  setPlayingTune(false);
                                } else {
                                  setPlayingTune(item);
                                }
                              }}
                            ></div>
                          </div>

                          <span className="line"></span>
                        </div>
                      );
                    })}
                </div>
                <div className="foot">
                  <div
                    className={name ? "next back" : "next back disabled"}
                    onClick={() => {
                      if (name !== false) {
                        setPlayingTune(false);
                        setSelectedTune(false);
                        setCurrentTune(false);
                        if (Tune.current) {
                          Tune.current.pause();
                        }
                        setStep(1);
                      }
                    }}
                  >
                    <img src={arrow} />
                  </div>
                  <div
                    className={selectedTune ? "btn" : "btn disabled"}
                    onClick={() => {
                      if (selectedTune !== false) {
                        if (Tune.current) {
                          Tune.current.pause();
                        }
                        trackAction("genre", { value: selectedTune?.genre });
                        setStep(3);
                      }
                    }}
                  >
                    Generera
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 3 && isMobile && (
            <div className="step _3">
              <div className="ccontent">
                <img src={flogo} />
                <p>
                  Dela den direkt eller ladda ner för att skicka inbjudan till
                  en snackmacka.
                </p>
              </div>
              <div className="s2content">
                <div
                  className="playerHolder"
                  style={vidHeight ? { height: vidHeight } : {}}
                  ref={videoHolder}
                >
                  {!isPlaying && (
                    <div
                      className="play"
                      onClick={() => {
                        if (Vid.current) {
                          if (!isPlaying) {
                            if (
                              selectedTune?.genre.toLowerCase() === "hiphop"
                            ) {
                              Vid.current.seekTo(0, "seconds");
                            }
                            setIsPlaying(true);
                          }
                        }
                      }}
                    ></div>
                  )}
                  {isPlaying && (
                    <div
                      className="stop"
                      onClick={() => {
                        if (Vid.current) {
                          if (isPlaying) {
                            if (
                              selectedTune?.genre.toLowerCase() === "hiphop"
                            ) {
                              Vid.current.seekTo(1, "seconds");
                            }
                            setIsPlaying(false);
                          }
                          if (selectedTune?.genre.toLowerCase() === "hiphop") {
                            Vid.current.seekTo(1, "seconds");
                          } else {
                            Vid.current.seekTo(0, "seconds");
                          }
                        }
                      }}
                    ></div>
                  )}
                  {vidHeight !== false && selectedTune?.mp4 && (
                    <ReactPlayer
                      ref={Vid}
                      url={selectedTune.mp4}
                      width={(vidHeight * 9) / 16}
                      height={vidHeight}
                      className="player"
                      onReady={() => {
                        if (
                          !isPlaying &&
                          selectedTune?.genre.toLowerCase() === "hiphop"
                        ) {
                          Vid.current.seekTo(1, "seconds");
                        }
                      }}
                      playing={isPlaying}
                      onEnded={() => {
                        Vid.current.seekTo(1, "seconds");
                        setIsPlaying(false);
                      }}
                      style={{
                        pointerEvents: "none",
                        width: (vidHeight * 9) / 16,
                        height: vidHeight,
                      }}
                    />
                  )}
                </div>
                <div className="foot">
                  <div className="btnRow">
                    <div
                      className={
                        selectedTune ? "btn last" : "btn last disabled"
                      }
                      onClick={async () => {
                        if (webShareSupported) {
                          trackAction("share_download_mobile", { value: 1 });
                          showBigLoader(true);
                          const blob = await fetch(selectedTune?.mp4).then(
                            (res) => res.blob(),
                          );
                          showBigLoader(false);
                          await shareOrDownload(
                            blob,
                            `Snackmackan_${selectedTune?.genre.toLowerCase()}_${name.share_name.toLowerCase()}.mp4`,
                            "Snackmackan",
                            `Hej ${name.share_name.charAt(0).toUpperCase() + name.share_name.slice(1)}! Här är din inbjudan.`,
                          );
                        } else {
                          trackAction("share_copy_link", { value: 1 });
                          setOpenCopy(true);
                        }
                      }}
                    >
                      Dela videon!
                    </div>
                    <div
                      className={
                        selectedTune ? "btn last" : "btn last disabled"
                      }
                      onClick={() => {
                        trackAction("share_copy_link", { value: 1 });
                        setOpenCopy(true);
                      }}
                    >
                      Kopiera länk
                    </div>
                  </div>
                  {/* <div className="btnRow social">
                    <div className="social">
                      <img src={facebook} />
                    </div>
                    <div className="social">
                      <img src={whatsapp} />
                    </div>
                  </div>*/}
                </div>
              </div>
            </div>
          )}
          {step === 3 && !isMobile && (
            <>
              <div className="step _3">
                <div className="ccontent">
                  <img src={flogo} />
                  <p>
                    Dela den direkt eller ladda ner för att skicka vidare
                    inbjudan till en snackmacka.
                  </p>
                </div>
                <div className="s2content">
                  <div className="foot">
                    <div className="btnRow">
                      <div
                        className={selectedTune ? "btn" : "btn disabled"}
                        onClick={() => {
                          trackAction("share_copy_link", { value: 1 });
                          setOpenCopy(true);
                        }}
                      >
                        Kopiera länk
                      </div>
                      <div
                        className={selectedTune ? "btn" : "btn disabled"}
                        onClick={async () => {
                          if (selectedTune?.mp4) {
                            trackAction("download_desk", { value: 1 });
                            showBigLoader(true);
                            const blob = await fetch(selectedTune?.mp4).then(
                              (res) => res.blob(),
                            );
                            showBigLoader(false);
                            const a = document.createElement("a");
                            a.download = `Snackmackan_${selectedTune?.genre}_${name.share_name}.mp4`;
                            a.style.display = "none";
                            a.href = URL.createObjectURL(blob);
                            a.addEventListener("click", () => {
                              setTimeout(() => {
                                URL.revokeObjectURL(a.href);
                                a.remove();
                              }, 1000);
                            });
                            document.body.append(a);
                            a.click();
                          }
                        }}
                      >
                        Ladda ner
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="playerHolder" ref={videoHolder}>
                {!isPlaying && (
                  <div
                    className="play"
                    onClick={() => {
                      if (Vid.current) {
                        if (!isPlaying) {
                          if (selectedTune?.genre.toLowerCase() === "hiphop") {
                            Vid.current.seekTo(0, "seconds");
                          }
                          setIsPlaying(true);
                        }

                        Vid.current.playing = true;
                        // Vid.current
                        //   .play()
                        //   .then()
                        //   .catch((err) => {});
                      }
                    }}
                  ></div>
                )}
                {isPlaying && (
                  <div
                    className="stop"
                    onClick={() => {
                      if (Vid.current) {
                        if (isPlaying) {
                          if (selectedTune?.genre.toLowerCase() === "hiphop") {
                            Vid.current.seekTo(1, "seconds");
                          }
                          setIsPlaying(false);
                        }
                        if (selectedTune?.genre.toLowerCase() === "hiphop") {
                          Vid.current.seekTo(1, "seconds");
                        } else {
                          Vid.current.seekTo(0, "seconds");
                        }
                      }
                    }}
                  ></div>
                )}
                {vidHeight !== false && selectedTune?.mp4 && (
                  <ReactPlayer
                    ref={Vid}
                    url={selectedTune.mp4}
                    className="player"
                    playing={isPlaying}
                    onReady={() => {
                      if (
                        !isPlaying &&
                        selectedTune?.genre.toLowerCase() === "hiphop"
                      ) {
                        Vid.current.seekTo(1, "seconds");
                      }
                    }}
                    onEnded={() => {
                      if (selectedTune?.genre.toLowerCase() === "hiphop") {
                        Vid.current.seekTo(1, "seconds");
                      }
                      setIsPlaying(false);
                    }}
                    width="100%"
                    height="100%"
                    style={{
                      pointerEvents: "none",
                    }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {bigLoader && (
        <div className="overlay">
          <Loader size={300} />
        </div>
      )}
      {(open === true || openCopy === true) && (
        <div className="overlay">
          <div className={!openCopy ? "overlayContent" : "overlayContent copy"}>
            <div
              className={"close"}
              onClick={() => {
                setFiltered("");
                setFiltNames([]);
                setShowNickNames(false);
                setOpen(false);
                setOpenCopy(false);
              }}
            >
              <img src={close} />
            </div>
            {openCopy && (
              <div className="copyHolder">
                <div className="plateHolder custom">
                  <div className="plate back">Kopiera länk!</div>
                </div>
                <p>Kopiera länken och skicka din inbjudan.</p>
                <span className="link">{`https://dela.snackmackan.se/${selectedTune?.genre.toLowerCase()}/${name?.share_name.toLowerCase()}`}</span>
                <div
                  className="btn"
                  onClick={() => {
                    copy(
                      `https://dela.snackmackan.se/${selectedTune?.genre.toLowerCase()}/${name?.share_name.toLowerCase()}`,
                    );
                    gsap.to(".copied", 1, { opacity: 1 });
                    gsap.to(".copied", 1, { opacity: 0, delay: 1 });
                  }}
                  // onClick={async () => {
                  //   const permissions = await navigator.permissions.query({
                  //     name: "clipboard-write",
                  //   });
                  //   if (
                  //     permissions.state === "granted" ||
                  //     permissions.state === "prompt"
                  //   ) {
                  //     gsap.to(".copied", 1, { opacity: 1 });
                  //     gsap.to(".copied", 1, { opacity: 0, delay: 1 });
                  //     await navigator.clipboard.writeText(
                  //       `https://dela.snackmackan.se/${selectedTune?.genre.toLowerCase()}/${name?.share_name.toLowerCase()}`,
                  //     );
                  //   }
                  // }}
                >
                  Kopiera
                </div>
                <p className="copied">Din länk är kopierad</p>
              </div>
            )}
            {!openCopy && !showNickNames && (
              <>
                <div className="search">
                  <h2>Välj ett namn*</h2>
                  <div className="inputholder">
                    <input
                      type="text"
                      value={filtered}
                      placeholder={"Sök namn..."}
                      onChange={(e) => {
                        setFiltered(e.target.value);
                      }}
                      className="searchField"
                    />
                  </div>
                </div>
                <div className="list">
                  {miniLoader && <Loader />}
                  {getList()}
                </div>
                <div className="note">
                  <p>
                    Hittar du inte namnet?
                    <br />
                    Prova ett{" "}
                    <span
                      className="blankBtn surname"
                      onClick={() => {
                        setShowNickNames(true);
                      }}
                    >
                      smeknamn
                    </span>{" "}
                    istället.
                  </p>
                  <p>
                    *Vi har listat de 257 vanligaste namnen i folkbokföringen
                    för personer födda mellan 2008-2014.
                  </p>
                </div>
              </>
            )}
            {!openCopy && showNickNames && (
              <>
                <div className="search">
                  <h2>
                    Välj ett
                    <br />
                    smeknamn
                  </h2>
                </div>
                <div className="list">{getList(true)}</div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default Create;
