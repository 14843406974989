import { useState, useRef } from "react";
import { isMobile } from "react-device-detect";

import Slider from "../global/Slider";
import { trackAction } from "../../data/Tracker";
import flogo from "../../assets/flogo.png";
import plogo from "../../assets/plogo.png";
import logo from "../../assets/logo.svg";
import heart from "../../assets/heart.svg";
import playBtn from "../../assets/play.png";
import psst from "../../assets/psst.svg";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const bgVideo = isMobile
    ? require("../../assets/mbg.mp4")
    : require("../../assets/dbg.mp4");

  const navigate = useNavigate();
  let w = isMobile ? window.innerWidth * 0.85 : 400;
  if(w > 500){
    w = 500
  }
  let h = isMobile ? (w * 16) / 9 : (w * 16) / 9;
  return (
    <>
      <div className="header">
        <div className="videoLoop">
          <div className="video-container"></div>
          <video
            id="bgVideo"
            autoPlay="autoplay"
            loop="loop"
            muted
            preload="true"
            playsInline={true}
            disablePictureInPicture={true}
          >
            <source src={bgVideo} type="video/mp4" />
          </video>
        </div>
        <div className="header-content">
          <div className="logos">
            <a href="https://www.polarbrod.se/" target="_blank">
              <img src={plogo} className="plogo" />
            </a>
            <a href="https://friends.se/" target="_blank">
              <img src={flogo} className="flogo" />
            </a>
          </div>
          <div className="title">
            <div className="title-content">
              <img src={logo} />
              <h1>
                Skicka en personlig musikinbjudan
                <br />
                till ett viktigt snack.
              </h1>
              <div
                className="btn start"
                onClick={() => {
                  trackAction("go_create", { value: 1 });
                  navigate("/create");
                }}
              >
                Gör din egen inbjudan!
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="contentHolder start">
          <h2>
            En enkel macka kan öppna
            <br />
            upp för svåra ämnen
          </h2>
          {isMobile ? (
            <p>
              Att ta sig tid, mitt i vardagen för att prata om allt mellan
              himmel och jord är bland det viktigaste man kan göra för förebygga
              psykisk ohälsa och mobbning hos barn.
            </p>
          ) : (
            <p>
              Att ta sig tid, mitt i vardagen och sätta sig ner med ett barn
              eller ungdom i sin närhet och prata om allt mellan himmel och jord
              är bland det viktigaste man kan göra för att förebygga psykisk
              ohälsa och mobbning. Det är när vi umgås, utan distraktioner och
              prestationer som vi kommer varandra nära och kan fånga upp om det
              är något som inte stämmer.
            </p>
          )}
          <img src={heart} alt="heart" className="heart" />
          <h2>Polarbröd {`<`}3 Friends</h2>
          {isMobile ? (
            <p>
              Tillsammans med Friends har vi tagit fram den här
              inbjudningstjänsten, för att locka barnen ur telefonen, till
              köksbordet eller soffan, där en snackmacka väntar.
            </p>
          ) : (
            <p className="nextLastBlock">
              Därför har vi tagit fram den här inbjudningstjänsten, tillsammans
              med Friends. För att locka barnen ur telefonen och till köksbordet
              eller soffan, där en snackmacka väntar.
            </p>
          )}
          <a
            href="https://friends.se/kunskapsbanken/guide/prata-med-ditt-barn-om-mobbning/"
            className="friendslink"
            onClick={() => {
              trackAction("friends_click", { value: 1 });
            }}
            target="_blank"
          >
            <span>Här</span> hittar du Friends råd och verktyg för att prata med
            barn och unga om svåra ämnen.
          </a>
          <p className="lastBlock">
            Såhär kan det låta!
            <br />
            Lyssna och välj vilken
            <br />
            genre du gillar bäst.
          </p>
        </div>
      </div>
      <div
        className="bottVideoHolder"
        style={
          !isMobile
            ? { height: h, justifyContent: "center", overflowX: "hidden" }
            : { height: h, justifyContent: "center" }
        }
      >
        {/*  <div
          className="info"
          style={
            !isMobile
              ? { width: "unset", left: "45%", transform: "translateX(-50%)" }
              : {}
          }
        >
          <img src={psst} />
          <p>
            Spela med
            <br />
            ljud på
          </p>
        </div>*/}
        <Slider width={isMobile ? window.innerWidth : w} custW={w} height={h} />
      </div>
    </>
  );
};

export default Home;
