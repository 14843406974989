import {
  useImperativeHandle,
  useEffect,
  useState,
  useRef,
  forwardRef,
} from "react";
import Vimeo from "@u-wave/react-vimeo";


const Player = forwardRef((props, ref) => {
  const { video, width, height } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const [isReady,setIsReady]=useState(false)
  const labels=[
  {
    title:'EDM',
    desc:'"Flera sorters pålägg och tid för varann"'
  },
  {
    title:'Rockballad',
    desc:'"Jag har gjort mackor till oss"'
  },
  {
    title:'HipHop',
    desc:'"En macka utan distraktioner"'
  }
  ]
  const Vid = useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        stopVideo() {
          if (Vid.current) {
            setIsPlaying(false);

            Vid.current.player
              .pause()
              .then()
              .catch((err) => {});
            Vid.current.player
              .setCurrentTime(0)
              .then()
              .catch((err) => {});
          }
        },
      };
    },
    [],
  );

  return (
    <div className="bottVideo" style={props.style} ref={ref}>
      {isReady && !isPlaying && (
        <div
          className="play"
          onClick={() => {
            if (Vid.current) {
              if (!isPlaying) {
                setIsPlaying(true);
              }
              Vid.current.player
                .play()
                .then()
                .catch((err) => {});
            }
          }}
        ></div>
      )}
      {isReady && isPlaying && (
        <div
          className="stop"
          onClick={() => {
            if (Vid.current) {
              if (isPlaying) {
                setIsPlaying(false);
              }
              Vid.current.player
                .pause()
                .then()
                .catch((err) => {});
              Vid.current.player
                .setCurrentTime(0)
                .then()
                .catch((err) => {});
            }
          }}
        ></div>
      )}

      <Vimeo
        ref={Vid}
        video={video}
        style={{ pointerEvents: "none" }}
        width={width}
        height={height}
        onReady={()=>{
          setIsReady(true)
        }}
        onEnd={() => {
          setIsPlaying(false);
        }}
        className="player"
        controls={false}
      />
       
       {isReady && (<div className="subholder">
          {/*<h2>{labels[props.subid-1].title}</h2>
          <p>{labels[props.subid-1].desc}</p>*/}
          <img className="first" src={require(`../../assets/labels/genre${props.subid}.svg`)} />
          <img src={require(`../../assets/labels/sub${props.subid}.svg`)} />
        </div>)}
    </div>
  );
});

export default Player;
