import { useState, useEffect } from "react";
import { withCookies, Cookies } from "react-cookie";

import moment from "moment-timezone";
import GA4React from 'ga-4-react'
import kaka from "../../assets/cookie.png";
import {useLocation}from 'react-router-dom'

const trackID = "G-DLEBDS0F4E";
const ga4react = new GA4React(
        trackID,
        { /* ga custom config, optional */ },
        [ /* additional code, optional */ ],
        5000 /* timeout, optional, defaults is 5000 */,
        /* { nonce: ['first-script-is-async','second-script'] } */
      );
const CookieChecker = (props) => {
  const { cookies } = props;

  
  const location = useLocation();
  const cookieName = "plbf";
  const [showCookieConcent, setShowCookieConcent] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const setTracker = () => {
    new Promise((resolve) => {
      (function (i, s, o, g, r, a, m) {
        i.GoogleAnalyticsObject = r;
        i[r] =
          i[r] ||
          function () {
            (i[r].q = i[r].q || []).push(arguments);
          };
        i[r].l = 1 * new Date();
        a = s.createElement(o);
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        a.addEventListener("load", resolve);
        m.parentNode.insertBefore(a, m);
      })(
        window,
        document,
        "script",
        "//www.google-analytics.com/analytics.js",
        "ga",
      );

      window.ga("create", trackID, "auto");
      window.ga("require", "displayfeatures");
      window.ga("send", "pageview");
    }).then(() => {
      // ReactGA.initialize(trackID);
      // ReactGA.plugin.require("displayfeatures");
      // ReactGA.pageview("/index.html");      
      ga4react.initialize().then((ga4) => {
        ga4.pageview(location.pathname)
        ga4.gtag('event','pageview',location.pathname)
      },(err) => {
        console.error(err)
      })
    });

    new Promise((resolve) => {
      (function (i, s, o, g, r, a, m) {
        i.GoogleAnalyticsObject = r;
        i[r] =
          i[r] ||
          function () {
            (i[r].q = i[r].q || []).push(arguments);
          };
        i[r].l = 1 * new Date();
        a = s.createElement(o);
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        a.addEventListener("load", resolve);
        m.parentNode.insertBefore(a, m);
      })(
        window,
        document,
        "script",
        "//www.googletagmanager.com/gtag/js?id=" + trackID,
        "gtag",
      );
      window.gtag("js", new Date());
      window.gtag("config", trackID);
    }).then(() => {});
  };

  const removeCookies = () => {
    let allC = cookies.getAll();
    Object.keys(allC).map((key, val) => {
      cookies.remove(key, { path: "/", domain: ".snackmackan.se" });
      cookies.remove(key, { path: "/", domain: "localhost" });
      cookies.remove(key, { path: "/", domain: ".vimeo.com" });
      cookies.remove(key, { path: "/", domain: "vimeo.com" });
    });
  };

  const setCookie = (val) => {

    removeCookies();

    let h = window.location.hostname;
    cookies.set(cookieName, val, {
      path: "/",
      expires: moment().add(1, "year").toDate(),
    });
    if (val === 2) {
      window.trackerSet = true;
      setTracker();
    }
  };
  const checkChange = () => {
    let c = props.cookies.get(cookieName);
    if (!c || c === undefined) {

      setShowCookieConcent(true);
    } else {
      setShowCookieConcent(false);
    }
  };

  useEffect(() => {

    props.cookies.changeListeners.push(checkChange);
    let c = props.cookies.get(cookieName);
    window.trackerSet = false;

    if (!c || c === undefined) {
      setShowCookieConcent(true);
    } else if(c===2) {
      window.trackerSet = true;
      setTracker();
    }
  }, []);

  useEffect(() => {
    if (showCookieConcent) {
      window.scrollTo(0, 0);
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  });
  if (!showCookieConcent) {
    return null;
  } else {
    return (
      <div className="cookieOverlay">
        {!readMore && (
          <div className="cookiePop">
            <p className="label">
              Cookies
              <img src={kaka} />
            </p>
            <p>
              Vi använder cookies för att analysera användningen av vår site.
            </p>
            <div className="btnholder">
              <div
                className="cookieBtn active"
                onClick={() => {
                  setCookie(2);
                }}
              >
                Acceptera
              </div>
              <div
                className="cookieBtn"
                onClick={() => {
                  setCookie(1);
                }}
              >
                Neka
              </div>
            </div>
            <div
              className="link"
              onClick={() => {
                setReadMore(true);
              }}
            >
              <p>Läs mer om våra cookies</p>
            </div>
          </div>
        )}
        {readMore && (
          <div className="readMore">
            <div className="tex">
              <div
                className="close"
                onClick={() => {
                  setReadMore(false);
                }}
              >
                Tillbaka
              </div>
              <b className="lihead">Cookies</b>
              <p>
                Webbplatsen använder sig av cookies. Detta innebär att
                information kan komma att lagras i användarnas webbläsare när de
                besöker webbplatsen. Syftet med detta är att webbplatsen ska
                känna igen en användares dator, föra statistik och i sin tur
                förbättra användarupplevelsen.
              </p>
              <p>
                En cookie är en liten textfil som gör det möjligt att
                identifiera en användares kommunikationsmedel utan att lagra
                personuppgifter. Det finns två olika typer av cookies,
                tidsbegränsade och sessionscookies. Tidsbegränsade cookies
                sparas under en längre tid och kan tex användas för att visa
                användaren sådant innehåll som tidigare missats. Sessionscookies
                raderas när webbplatsen/webbläsaren stängs ned. Denna typ av
                cookies kan lagra information såsom valt språk och medför att
                användare inte behöver välja om detta på nytt varje gång de
                klickar sig vidare på webbplatsen.
              </p>
              <br />

              <b>Cookie Förteckning</b>

              <p>
                Icke nödvändiga cookies (analys) placeras efter att samtycke har
                inhämtats. Samtliga cookies lagrar all data anonymiserat.
              </p>

              <div className="row small " style={{ marginTop: 10 }}>
                <div className="num">•</div>
                <div className="texline">
                  <p>
                    <b>{cookieName}</b>
                    <br />
                    www.snackmackan.se
                    <br />
                    En kaka som vi sparar om du godkänt cookies.
                    <br />
                    Livslängd: 1 år
                    <br />
                    Data delas med: Polarbröd
                  </p>
                </div>
              </div>
              {/*     <div className="row small ">
                <div className="num">•</div>
                <div className="texline">
                  <p>
                    <b>_fbp:</b>
                    <br />
                    www.snackmackan.se
                    <br />
                    En Facebook Pixel-cookie används av Facebook för att erbjuda
                    anpassade annonser och spåra ditt besök på vår webbplats.
                    <br />
                    Livslängd: 3 månader
                    <br />
                    Data delas med: Facebook
                  </p>
                </div>
              </div>*/}
              <div className="row small ">
                <div className="num">•</div>
                <div className="texline">
                  <p>
                    <b>_ga</b>
                    <br />
                    www.snackmackan.se
                    <br />
                    Google Analytics-kakor som används för att spåra
                    användaraktivitet och generera statistik om hur besökare
                    använder webbplatsen.
                    <br />
                    Livslängd: 1 år
                    <br />
                    Data delas med: Google
                  </p>
                </div>
              </div>
              {/*    <div className="row small ">
                <div className="num">•</div>
                <div className="texline">
                  <p>
                    <b>_ga_PE90MP5KPP</b>
                    <br />
                    www.snackmackan.se
                    <br />
                    Google Analytics-kakor som används för att spåra
                    användaraktivitet och generera statistik om hur besökare
                    använder webbplatsen.
                    <br />
                    Livslängd: 30 dagar
                    <br />
                    Data delas med: Google
                  </p>
                </div>
              </div>*/}
              <div className="row small ">
                <div className="num">•</div>
                <div className="texline">
                  <p>
                    <b>_gid</b>
                    <br />
                    www.snackmackan.se
                    <br />
                    En Google Analytics-kaka som används för att skilja
                    användare åt.
                    <br />
                    Livslängd: 1 vecka
                    <br />
                    Data delas med: Google
                  </p>
                </div>
              </div>

              <b style={{ marginTop: 10 }}>Hur man tar bort cookies?</b>
              <p>
                För att enbart radera cookies från tjänsten, se ovan detaljerad
                förteckning där användaren kan se vilka cookies som används till
                vad, samt vad de heter.
              </p>
              <p>
                För att enbart radera cookies från tjänsten, se ovan detaljerad
                förteckning där användaren kan se vilka cookies som används till
                vad, samt vad de heter.
              </p>

              <p>
                För att ta bort cookies ska användaren följa instruktionen för
                sin specifika webbläsare. Nedan återfinns länkar till de
                vanligaste webbläsarna. Tyvärr erbjuder inte alla leverantörer
                av webbläsare instruktioner om hur man går tillväga för att
                enbart ta bort specifika cookies om man inte vill rensa hela sin
                cookiehistorik.
              </p>

              <a
                style={{ marginTop: 5 }}
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=sv"
                target="_blank"
              >
                Radera cookies från Google Chrome
              </a>
              <a
                href="https://support.mozilla.org/sv/kb/rensa-kakor-och-webbplatsdata-firefox?redirectlocale=sv&redirectslug=ta-bort-kakor-informationen-webbplatser-lagrat"
                target="_blank"
              >
                Radera cookies från Firefox
              </a>
              <a
                href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                target="_blank"
              >
                Radera cookies från Microsoft Edge
              </a>
              <a
                href="https://help.opera.com/en/latest/web-preferences/#cookies"
                target="_blank"
              >
                Radera cookies från Opera
              </a>
              <a
                href="https://support.apple.com/sv-se/HT201265"
                target="_blank"
              >
                Radera cookies från Safari
              </a>

              <p style={{ marginTop: 15 }}>
                Länkarna ovan går till respektive webbläsares egna
                instruktionssidor, över vilka Polarbröd inte har någon kontroll
                – Polarbröd kan därmed inte hållas ansvariga för eventuella
                felaktigheter i dessa.
              </p>
              <div className="padd"></div>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default withCookies(CookieChecker);
