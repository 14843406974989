import { useRef, useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import Player from "./Player";
import nextImg from "../../assets/right.svg";
import prevImg from "../../assets/left.svg";

const Slider = (props) => {
  const { width, height, custW } = props;
  const [activeSlide, setactiveSlide] = useState(1);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;

  const vid1 = useRef(null);
  const vid2 = useRef(null);
  const vid3 = useRef(null);

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };
  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe)
      if (isLeftSwipe) {
        next();
      } else if (isRightSwipe) {
        prev();
      }
  };

  const next = () => {
    vid1.current.stopVideo();
    vid2.current.stopVideo();
    vid3.current.stopVideo();
    if (activeSlide < 3 - 1) {
      setactiveSlide(activeSlide + 1);
    }
  };
  const prev = () => {
    vid1.current.stopVideo();
    vid2.current.stopVideo();
    vid3.current.stopVideo();
    if (activeSlide > 0) {
      setactiveSlide(activeSlide - 1);
    }
  };

  const getStyles = (index) => {
    if (activeSlide === index)
      return {
        opacity: 1,
        transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
        zIndex: 10,
      };
    else if (activeSlide - 1 === index)
      return {
        opacity: 1,
        transform: "translateX(-300px) translateZ(-400px) rotateY(35deg)",
        zIndex: 9,
      };
    else if (activeSlide + 1 === index)
      return {
        opacity: 1,
        transform: "translateX(300px) translateZ(-400px) rotateY(-35deg)",
        zIndex: 9,
      };
    else if (activeSlide - 2 === index)
      return {
        opacity: 1,
        transform: "translateX(-600px) translateZ(-500px) rotateY(35deg)",
        zIndex: 8,
      };
    else if (activeSlide + 2 === index)
      return {
        opacity: 1,
        transform: "translateX(600px) translateZ(-500px) rotateY(-35deg)",
        zIndex: 8,
      };
    else if (index < activeSlide - 2)
      return {
        opacity: 0,
        transform: "translateX(-600px) translateZ(-500px) rotateY(35deg)",
        zIndex: 7,
      };
    else if (index > activeSlide + 2)
      return {
        opacity: 0,
        transform: "translateX(600px) translateZ(-500px) rotateY(-35deg)",
        zIndex: 7,
      };
  };
  let extra = {};
  if (isMobile) {
    extra = { left: (width - custW) / 2 };
  }

  return (
    <>
      <div
        className="slider"
        style={{ width, height }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <Player
          ref={vid1}
          width={isMobile ? custW : width}
          height={height}
          style={{
            ...extra,
            ...getStyles(0),
            ...{ pointerEvents: activeSlide !== 0 ? "none" : "auto" },
          }}
          video={"https://vimeo.com/927984232/0c111dad11"}
          subid={1}
        />
        <Player
          ref={vid2}
          width={isMobile ? custW : width}
          height={height}
          style={{
            ...extra,
            ...getStyles(1),
            ...{ pointerEvents: activeSlide !== 1 ? "none" : "auto" },
          }}
          video={"https://vimeo.com/927984283/f96a0b20df"}
          subid={2}
        />
        <Player
          ref={vid3}
          width={isMobile ? custW : width}
          height={height}
          style={{
            ...extra,
            ...getStyles(2),
            ...{ pointerEvents: activeSlide !== 2 ? "none" : "auto" },
          }}
          video={"https://vimeo.com/927984262/8f5c32790a"}
          subid={3}
        />

        {!isMobile && (
          <div
            className={isMobile ? "btns bigbox" : "btns bigbox"}
            pointerEvents={"box-none"}
            style={{
              top: 0, //isMobile ? 0 : height - height / 3,
              pointerEvent: "box-none",
            }}
          >
            <div
              className="nextprev prev"
              style={activeSlide === 0 ? { opacity: 0 } : {}}
              onClick={() => {
                if (activeSlide !== 0) {
                  prev();
                }
              }}
            >
              <img src={prevImg} />
            </div>
            <div
              className="nextprev "
              style={activeSlide === 2 ? { opacity: 0 } : {}}
              onClick={() => {
                if (activeSlide !== 2) {
                  next();
                }
              }}
            >
              <img src={nextImg} />
            </div>
          </div>
        )}
      </div>
      {isMobile && (
        <div className="dottHolder">
          <div className={activeSlide === 0 ? "dott active" : "dott"}></div>
          <div className={activeSlide === 1 ? "dott active" : "dott"}></div>
          <div className={activeSlide === 2 ? "dott active" : "dott"}></div>
        </div>
      )}
    </>
  );
};

export default Slider;
